<template>
  <v-container
    class="pa-0 pr-4"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.email"
          outlined
          label="Email"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getSellers"
          v-model="filter.seller"
          label="Vendedor"
          :items="seller.items"
          item-text="name"
          item-value="id"
          outlined
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="seller.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-center text-h6 grey--text"
            >
              Nenhum vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.phone"
          outlined
          label="Telefone"
          rounded
          dense
          v-mask="'(##) #####-####'"
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.code_client"
          outlined
          label="Código do cliente"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    line_of_business: [],
    seller: {
      items: [],
      loading: true,
    },
  }),
  methods: {
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.seller.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>

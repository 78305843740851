<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.company_name"
          outlined
          label="Razão social"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.trading_name"
          outlined
          label="Nome fantasia"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.email"
          outlined
          label="Email"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.phone"
          outlined
          label="Telefone"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="filter.status"
          :items="status"
          item-text="title"
          item-value="value"
          label="Status"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          @focus.once="getSegments"
          v-model="filter.segment"
          outlined
          :items="segment.items"
          item-text="segment"
          item-value="id"
          label="Segmento"
          rounded
          dense
          clearable
          clear-icon="fas fa-times"
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="segment.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-center text-h6 grey--text"
            >
              Nenhum segmento encontrado
            </h6>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.cnpj"
          outlined
          label="CNPJ"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de atualização </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    segment: {
      items: [],
      loading: true,
    },
    status: [
      { title: 'Inativo', value: false },
      { title: 'Ativo', value: true },
    ],
  }),
  methods: {
    getSegments() {
      this.$api
        .get('segments')
        .then((res) => {
          this.segment.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.segment.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>

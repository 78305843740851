export default {
  data: () => ({
    master: [],
    seller: [
      'people-dashboard',
      'clients',
      'clients-edit',
      'clients-new',
      'agencies',
      'agencies-edit',
      'agencies-new',
      'search-clients',
      'orders',
      'orders-edit',
      'order-products',
      'budgets',
      'budgets-edit',
      'budgets-new',
      'products',
      'crm',
      'crm-edit',
      'crm-new',
      'colors',
      'colors-edit',
      'colors-new',
      'factor',
      'factor-edit',
      'factor-new',
      'composition',
      'composition-edit',
      'composition-new',
      'print',
      'print-edit',
      'print-new',
      'ncm',
      'ncm-edit',
      'ncm-new',
      'stockDashboard',
    ],
    production: ['orders-edit', 'order-products'],
    editor: [
      'categories',
      'categories-edit',
      'categories-new',
      'colors',
      'colors-edit',
      'colors-new',
      'products',
      'products-edit',
      'search-clients',
      'orders',
      'orders-edit',
      'budgets',
      'budgets-edit',
      'siteBanners',
      'siteFaq',
      'siteContacts',
      'siteEmailNews',
      'sitePrints',
    ],
    admin: [],
  }),
  methods: {
    checkRoutePermission(route, profile) {
      switch (profile) {
        case 'admin':
          this.routeCheck(route, this.admin)
          break
        case 'seller':
          this.routeCheck(route, this.seller)
          break
        case 'editor':
          this.routeCheck(route, this.editor)
          break
        case 'production':
          this.routeCheck(route, this.production)
          break
      }
    },
    routeCheck(route, profile) {
      if (profile.some((el) => el === route)) {
        return
      } else {
        this.$router.go(-1)
      }
    },
  },
  beforeMount() {
    this.checkRoutePermission(this.$route.name, this.$cookies.get('type'))
  },
}

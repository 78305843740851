<template>
  <v-form class="px-5 mt-5" ref="form">
    <v-row dense>
      <v-col cols="12" lg="3">
        <v-avatar
          style="cursor: pointer"
          width="120px"
          height="170px"
          color="grey lighten-4 elevation-2"
          rounded
          @click="selectImage"
        >
          <v-img
            v-if="temporary.temp_image != null || temporaryImage != null"
            :src="
              temporaryImage != null
                ? temporaryImage
                : $store.state.imagePath + temporary.temp_image
            "
            class="c-profile-picture"
            alt="foto de perfil"
          />
          <v-icon v-else color="primary">fas fa-image</v-icon>
        </v-avatar>
        <input
            ref="uploader"
            id="image_path"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChange"
          />
      </v-col>
      <v-col cols="12" lg="9">
        <v-text-field
          v-model="temporary.product_name"
          label="Nome"
          placeholder="Nome do produto"
          outlined
          rounded
          dense
        />
        <v-text-field
          v-model="temporary.temp_vendor_name"
          label="Fornecedor"
          auto-grow
          outlined
          dense
          rounded
          rows="10"
          row-height="15"
        />
        <v-text-field
          v-model="temporary.temp_vendor_reference"
          label="Referência do Fornecedor"
          auto-grow
          outlined
          dense
          rounded
          rows="10"
          row-height="15"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="temporary.product_height"
              label="Altura (mm)"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="temporary.product_width"
              label="Largura (mm)"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="temporary.product_depth"
              label="Profundidade (mm)"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="temporary.product_description"
          label="Descrição"
          auto-grow
          outlined
          dense
          rounded
          rows="10"
          row-height="15"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      temporary: {
        is_temporary: true
      },
      temporaryImage: null
    }
  },
  methods: {
    selectImage(){
      this.$refs.uploader.click()
    },
    onFileChange(e){
      this.temporaryImage = URL.createObjectURL(e.target.files[0])
      this.temporary.temp_image = e.target.files[0]
    },
    createTemporary() {
      const formData = new FormData();
      const temporaryKeys = Object.keys(this.temporary);
      const temporaryValues = Object.values(this.temporary);

      for (let i = 0; i < temporaryKeys.length; i++) {
        if (temporaryValues[i] !== null) {
          formData.append(temporaryKeys[i], temporaryValues[i]);
        }
      }

      formData.append('is_temporary', true);

      this.$api.post("products/temp", formData)
      .then(res => {
        this.$emit('success', res.data.data)
      })
      .catch(err => {
        this.$emit('error', err)
      })
      .finally(() => {
        this.temporary = {}
        this.temporaryImage = null
      })
    },
  }
};
</script>

<template>
  <v-avatar
    class="elevation-3"
    color="grey lighten-2"
    rounded
    :size="bigImage ? 150 : 36"
  >
    <v-img
      v-if="img_src"
      :src="imagePath + img_src"
      cover
    />
    <v-icon
      v-else
      color="primary"
      small
    >
      {{ defaultIcon }}
    </v-icon>
  </v-avatar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    img_src: String,
    defaultIcon: {
      type: String,
      required: true,
    },
    bigImage: Boolean,
  },
  computed: {
    ...mapState(['imagePath']),
  },
}
</script>

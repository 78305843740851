<template>
  <v-container
    class="pa-0 pr-4"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          placeholder="Nome, razão social ou nome fantasia"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="filter.type"
          :items="type"
          item-text="title"
          item-value="id"
          label="Tipo"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="filter.status"
          :items="status"
          item-text="title"
          item-value="value"
          label="Status"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="dateFromConverted"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="dateToConverted"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    status: [
      { title: 'Inativo', value: false },
      { title: 'Ativo', value: true },
    ],
    type: [
      { id: 1, title: 'Principal' },
      { id: 2, title: 'Segmento' },
      { id: 3, title: 'Data especial' },
    ],
  }),
  methods: {
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>

<template>
  <v-container
    class="pa-0 pr-4"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.email"
          outlined
          label="Email"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          left
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <v-text-field
          v-model="filter.created_at_from"
          label="De"
          v-mask="['##/##/####']"
          placeholder="00/00/0000"
          readonly
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <v-text-field
          v-model="filter.created_at_to"
          label="Até"
          v-mask="['##/##/####']"
          placeholder="00/00/0000"
          readonly
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
  }),
  methods: {
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>

<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row dense>
      <v-col
        cols="12"
        lg="6"
      >
        <v-text-field
          v-model="filter.order_id"
          outlined
          label="Nº do pedido"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-select
          v-model="filter.status"
          :items="product_status"
          item-text="title"
          item-value="id"
          outlined
          label="Status"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.client_id"
          @focus.once="getClients"
          label="Cliente"
          :items="client.items"
          item-value="id"
          item-text="trading_name"
          rounded
          hide-details
          outlined
          dense
        >
          <template v-slot:no-data>
            <div v-if="client.isLoading">
              <v-progress-linear indeterminate />
            </div>
            <h6
              v-else
              class="text-center"
            >
              Nenhum cliente encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.print_id"
          label="Gravação"
          @focus.once="getPrints"
          :items="print.items"
          item-value="id"
          item-text="name"
          rounded
          hide-details
          outlined
          dense
        >
          <template v-slot:no-data>
            <div v-if="print.isLoading">
              <v-progress-linear indeterminate />
            </div>
            <h6
              v-else
              class="text-center"
            >
              Nenhuma gravação encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.product_reference"
          outlined
          label="Cód. do produto"
          placeholder="MB00000"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.product_name"
          outlined
          label="Nome do produto"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.color_id"
          @focus.once="getColors"
          outlined
          label="Cor"
          :items="colors"
          item-text="name"
          item-value="id"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <div
              v-if="colors.length == 0"
              class="pa-4"
            >
              <v-progress-linear
                color="primary"
                indeterminate
              />
            </div>
            <div
              class="text-h5 text-center"
              v-else
            >
              Nenhuma cor encontrada
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              color="primary"
              size="30"
            >
              <v-img
                v-if="item.image_color"
                :src="$store.state.imagePath + item.image_color"
              />
              <v-icon
                v-else
                color="white"
                small
              >
                fas fa-palette
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.seller_id"
          @focus.once="getSellers"
          :items="seller.items"
          item-value="id"
          item-text="name"
          label="Vendedor"
          rounded
          dense
          hide-details
          outlined
        >
          <template v-slot:no-data>
            <div v-if="seller.isLoading">
              <v-progress-linear indeterminate />
            </div>
            <h6
              v-else
              class="text-center"
            >
              Nenhum vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          left
        >
          fas fa-calendar
        </v-icon>
        <span class="text-body-2">Data de entrega</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.delivery_date_start"
          v-mask="['##/##/####']"
          outlined
          label="De:"
          rounded
          dense
          hide-details
          placeholder="00/00/0000"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.delivery_date_end"
          v-mask="['##/##/####']"
          outlined
          label="Até:"
          rounded
          dense
          hide-details
          placeholder="00/00/0000"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    colors: [],
    client: {
      items: [],
      isLoading: true,
    },
    print: {
      items: [],
      isLoading: true,
    },
    seller: {
      items: [],
      isLoading: true,
    },
    product_status: [
      { id: 41, title: 'Aguardando compra' },
      { id: 411, title: 'Parcialmente comprado' },
      { id: 42, title: 'Aguardando gravação' },
      { id: 421, title: 'Parcialmente gravado' },
      { id: 43, title: 'Aguardando conferência' },
      { id: 431, title: 'Parcialmente conferido' },
      { id: 44, title: 'Aguardando produção' },
      { id: 441, title: 'Parcialmente produzido' },
      { id: 45, title: 'Aguardando faturamento' },
      { id: 451, title: 'Parcialmente faturado' },
      { id: 46, title: 'Aguardando finalização' },
      { id: 47, title: 'Finalizado' },
    ],
  }),
  methods: {
    getColors() {
      this.$api
        .get('product_colors')
        .then((res) => {
          this.colors = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClients() {
      this.$api
        .get('people/type/clients')
        .then((res) => {
          this.client.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.client.isLoading = false
        })
    },
    getPrints() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.print.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.print.isLoading = false
        })
    },
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.seller.isLoading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
